const GamesModule = () => import(/* webpackChunkName: "games-module" */ './views/Module.vue');
const Games = () => import(/* webpackChunkName: "games" */ './views/Game.vue');

const GamesRoutes = {
    path: '/',
    component: GamesModule,
    children: [
        {
            path: 'game/:type/:gameCode?',
            name: 'game',
            component: Games
        }
    ],
}

export default GamesRoutes;