const CricketFightModule = () => import(/* webpackChunkName: "my-market-module" */ './views/Module.vue');
const CricketFight = () => import(/* webpackChunkName: "my-market" */ './views/CricketFight.vue');


const CricketFightRoutes = {
    path: '/',
    component: CricketFightModule,
    children: [
        {
            path: 'cric-fight',
            name: 'CricketFight',
            component: CricketFight
        },
        
    ],
}

export default CricketFightRoutes;