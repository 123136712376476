<template>
    <section v-if="$route.name == 'Home' && landingPage" v-html="landingPage"></section>
    <section v-else>
        <Header @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc"  v-if="checkForHeaderShow()" />
    <div v-if="onLine">
        <router-view @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" />
    </div>

    <div v-else>
        <NoInternetConnection />
    </div>
    <footer class="footer-wrapper"
        v-if="$route.name == 'Home' || $route.name == 'my-market' || $route.name == 'games' || $route.name == 'Inplay' || $route.name == 'bets'">
        <div class="container">
            <Footer></Footer>
        </div>
    </footer>
    </section>
    
    <transition name="fade">
        <Loader v-if="checkIsLoading"></Loader>
    </transition>

    <div :class="{ 'd-none': !showSuccessModal }" style="z-index: 9999999999999999;" ref="successmodal"
        id="successfullyModal">
        <SuccessModal :message="successMsg" />
    </div>

    <div :class="{ 'd-none': !showErrorModal }" style="z-index: 9999999999999999999999;" id="wrongtoaster">
        <ErrorModal :message="errorMsg" />
    </div>
</template>

<script>
import ErrorModal from "./shared/components/modal/ErrorModal.vue";
import SuccessModal from "./shared/components/modal/SuccessModal.vue";
import Header from '@/shared/components/header/Header.vue';
import Footer from './shared/components/footer/Footer.vue';
import axios from 'axios';
import { setHeaders } from '@/shared/services/headers';
import { AUTH_TOKEN } from "./shared/constants/cookies-const";
import api from '@/shared/services/api';
import * as apiName from '@/shared/services/urls';
import NoInternetConnection from './shared/components/no-internent/NoInternetConnection.vue';
import Loader from './shared/components/loader/Loader.vue';


export default {
    name: "App",
    components: {
        Header,
        Footer,
        NoInternetConnection,
        Loader,
        ErrorModal,
        SuccessModal
    },
    data() {
        return {
            onLine: navigator.onLine,
            showBackOnline: false,
            showSuccessModal: false,
            successMsg: "",
            showErrorModal: false,
            errorMsg: "",
            landingPage: ''
        }
    },
    computed: {
        checkIsLoading() {
            return this.$store?.getters?.loading;
        }
    },
    beforeMount() {
    },
    mounted() {
        if (this.$store?.getters?.isAuthenticated == false) {
            this.getDomain()
        }
        window.store = this.$store;
        window.cookies = this.$cookies;
        window.router = this.$router;
        window.authorization_token = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`

        axios.defaults.headers.common['Authorization'] = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`;
        setHeaders();

        window.addEventListener('scroll', () => {
            var scrollTop = window.scrollY || document.documentElement.scrollTop;

            if (scrollTop > 60) {
                document.querySelector('header')?.classList.add('show');
            } else {
                document.querySelector('header')?.classList.remove('show');
            }
        });

        window.addEventListener('online', this.updateOnlineStatus);
        window.addEventListener('offline', this.updateOnlineStatus);
        if (this.$store.getters.isAuthenticated) {
            this.getUserDataServiceCall()
        }

    },
    beforeDestroy() {
        window.removeEventListener('online', this.updateOnlineStatus);
        window.removeEventListener('offline', this.updateOnlineStatus);
    },
    methods: {
        async getDomain () {
            let domain = window.location.origin;
            api.post(apiName.GET_LANDING_PAGE, {domain:domain}).then(response => {
                this.loading = false;
                this.landingPage = response.data.data;
            }).catch(error => {
                this.loading = false;
                if (error) {
                    this.showErrorModalFunc(error[0]);
                }
            });
        },
        updateOnlineStatus(e) {
            const {
                type
            } = e;
            this.onLine = type === 'online';
        },
        checkForHeaderShow() {
            let routeName = this.$route.name;
            if (routeName == 'Login' || routeName == 'SignUp' || routeName == 'ForgetPassword' || routeName == 'NewPassword') {
                return false
            }
            return true
        },
        getUserDataServiceCall() {
            this.loading = true;
            let headers = {
                'Authorization': `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
            }
            api.get(apiName.USER_PROFILE, { headers }).then(response => {
                this.loading = false;
                if (response) {
                    if (response.status == 200) {
                        if (response?.data?.status != 0) {
                            let userData = response.data.data.profile;
                            this.$store.dispatch('setUserProfile', userData);
                        }
                    }
                }
            }).catch(error => {
                this.loading = false;
                if (error) {
                    this.showErrorModalFunc(error?.data?.message);
                }
            });
        },
        showErrorModalFunc(message) {
            if(message){
                this.errorMsg = message;
                this.showErrorModal = true;
                setTimeout(() => {
                    this.showErrorModal = false;
                }, 2000);
                //window.scrollTo(0, 0);
            }
           

        },
        showSuccessModalFunc(message) {
            if(message){

                this.successMsg = message;
                this.showSuccessModal = true;
                setTimeout(() => {
                    this.showSuccessModal = false;
                }, 2000);
                //window.scrollTo(0, 0);
            }
            
        },

    },
    watch: {
        onLine(v) {
            if (v) {
                this.showBackOnline = true;
                setTimeout(() => {
                    this.showBackOnline = false;
                }, 1000);
            }
        }
    }
};

</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 1s
}

.fade-enter,
.fade-leave-to {
    opacity: 0
}
</style>