<template>
    <div class="inner-body login-body-bg">
        <section class="register-header-wrapper">

            <div class="register-header-sec">
                <div class="container">
                    <div class="register-header-con">
                        <div class="black-arrow" @click="$router.push('/')">
                            <a href="Javascript:void(0);"><img src="@/assets/images/back-arrow.svg" alt=""></a>
                        </div>
                        <div class="register-header-det">
                            <div class="thm-heading">
                                <h3>Register</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="register-header-banner">
                <div class="container">
                    <div class="register-banner">
                        <div class="register-banner-logo">
                            <a href="#">
                                <img src="@/assets/images/logo.png" alt="">
                            </a>
                        </div>
                        <div class="thm-heading">
                            <h2>Register Account</h2>
                            <p>Create account with Mobile Number</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-wrapper">
                <div class="container">
                    <div class="form-sec">
                        <form>
                            <div class="form-con">

                                <div class="form-con-input ">
                                    <label class="form-label">Mobile Number</label>
                                    <div class="input-select">
                                        <input type="number" class="form-control" aria-describedby="s"
                                            placeholder="Enter Number" v-model.trim="v$.form.mobileNo.$model">
                                        <div class="left-input-sel">
                                            <div class="left-input-img">
                                                <img class="call-in-img show" src="@/assets/images/input-call-img.svg"
                                                    alt="">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-check ps-0 invalid-otp" v-if="valError && v$.form.mobileNo.required.$invalid">
                                    <label class="form-check-label otp-warn" for="flexCheckDefault">
                                        Mobile number is required
                                    </label>
                                </div>
                                <div class="form-con-input">
                                    <label class="form-label">OTP </label>
                                    <div class="input-select">
                                        <input type="number" class="form-control" aria-describedby="emailHelp"
                                            placeholder="Enter OTP" v-model="form.otp">

                                        <div class="left-input-sel">
                                            <div class="left-input-img">
                                                <img class="user-in-img show" src="@/assets/images/DotsSixVertical.svg"
                                                    alt="">
                                            </div>
                                        </div>

                                        <div class="right-input-sel">
                                            <div class="left-input-img w-100" v-if="!timerRunning">
                                                <button class="thm-btn border-0" @click.prevent="sendOtpCall()">Get OTP</button>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-check ps-0 invalid-otp" v-if="valError && v$.form.otp.required.$invalid">
                                        <label class="form-check-label otp-warn" for="flexCheckDefault">
                                            OTP is required
                                        </label>
                                    </div>
                                    <div class="form-check ps-0 invalid-otp" v-if="valError && v$.form.otp.minLength.$invalid">
                                        <label class="form-check-label otp-warn" for="flexCheckDefault">
                                            OTP length is 6
                                        </label>
                                    </div>

                                    <div class="forgot-sub-sec">
                                        <div class="form-check ps-0">
                                            <label class="form-check-label" for="flexCheckDefault" v-if="timerRunning">
                                                Time Remaining : {{ formattedTime }}
                                            </label>
                                        </div>
                                        <div class="forgot-sub-con" v-if="!timerRunning">
                                            <a href="javascript:void(0);" @click.prevent="sendOtpCall()">
                                                <h5><span>Resend OTP</span></h5>
                                            </a>
                                        </div>
                                    </div>
                                </div>


                                <div class="form-con-input ">
                                    <label class="form-label">User Name</label>
                                    <div class="input-select">
                                        <input type="text" class="form-control" aria-describedby="emailHelp"
                                            placeholder="Enter User Name" v-model="form.userName">
                                        <div class="left-input-sel">
                                            <div class="left-input-img">
                                                <img class="call-in-img show" src="@/assets/images/input-user-img.svg"
                                                    alt="">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-check ps-0 invalid-otp" v-if="valError && v$.form.userName.required.$invalid">
                                    <label class="form-check-label otp-warn" for="flexCheckDefault">
                                        User name is required
                                    </label>
                                </div>
                                <div class="form-check ps-0 invalid-otp" v-if="valError && v$.form.userName.minLength.$invalid">
                                    <label class="form-check-label otp-warn" for="flexCheckDefault">
                                        User name min length is 4
                                    </label>
                                </div>

                                <div class="form-con-input ">
                                    <label class="form-label">User ID</label>
                                    <div class="input-select">
                                        <input type="text" class="form-control" aria-describedby="emailHelp"
                                            placeholder="Enter User ID" v-model="form.userId">
                                        <div class="left-input-sel">
                                            <div class="left-input-img">
                                                <img class="call-in-img show" src="@/assets/images/input-user-img.svg"
                                                    alt="">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-check ps-0 invalid-otp" v-if="valError && v$.form.userId.required.$invalid">
                                    <label class="form-check-label otp-warn" for="flexCheckDefault">
                                        User id is required
                                    </label>
                                </div>
                                <div class="form-check ps-0 invalid-otp" v-if="valError && v$.form.userId.minLength.$invalid">
                                    <label class="form-check-label otp-warn" for="flexCheckDefault">
                                        User id min length is 4
                                    </label>
                                </div>
                                <div class="form-con-input">
                                    <label class="form-label">Password </label>
                                    <div class="input-select">
                                        <input type="password" class="form-control" aria-describedby="emailHelp"
                                            placeholder="Enter here" v-model="form.password">

                                        <div class="left-input-sel">
                                            <div class="left-input-img">
                                                <img class="user-in-img show" src="@/assets/images/lock-icon.svg" alt="">
                                            </div>
                                        </div>

                                        <div class="right-input-sel">
                                            <div class="left-input-img">
                                                <button class="border-0 bg-transparent"><img class="user-in-img show"
                                                        src="@/assets/images/eye-icon.svg" alt=""></button>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-check ps-0 invalid-otp" v-if="valError && v$.form.password.required.$invalid">
                                        <label class="form-check-label otp-warn" for="flexCheckDefault">
                                            Password is required
                                        </label>
                                    </div>
                                    <div class="form-check ps-0 invalid-otp" v-if="valError && v$.form.password.minLength.$invalid">
                                        <label class="form-check-label otp-warn" for="flexCheckDefault">
                                            Password min length is 8
                                        </label>
                                    </div>
                                </div>

                                <div class="form-con-input">
                                    <label class="form-label">Refer code </label>
                                    <div class="input-select">
                                        <input type="password" class="form-control" aria-describedby="emailHelp"
                                            placeholder="Enter here" v-model="form.referCode">

                                        <div class="left-input-sel">
                                            <div class="left-input-img">
                                                <img class="user-in-img show" src="@/assets/images/share-icon.svg" alt="">
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div class="form-con-input register-btn ">
                                    <div class="form-btn ">
                                        <a href="" class="thm-btn" @click.prevent="signUpCall()">Register</a>
                                    </div>
                                    <div class="forgot-sub-con text-center">

                                        <h5>Already have an account?
                                            <router-link :to="{ name: 'Login' }">
                                                <span>Login?</span>
                                            </router-link>
                                        </h5>
                                    </div>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>

import api from '../services/api';
import * as apiName from '../services/urls';
//import { toHandlerKey } from 'vue';
import { useVuelidate } from '@vuelidate/core'
import { required, minLength } from '@vuelidate/validators'

export default {
    name: "SignUp",
    setup () {
        return { v$: useVuelidate() }
    },
    data() {
        return {
            form: {
                code: 91,
                mobileNo: "",
                otp: '',
                userName: "",
                password: "",
                referCode: "",
                userId: ""
            },
            showValidationError: false,
            timerRunning: false,
                seconds: 120, // 2 minutes
                timerInterval: null,
            showErrorMobile: false,
            passwordValidation: false,
            loadingSignUp: false,
            loadingOtp: false,
            otpValidation: false,

            showSuccessModal: false,
            successMsg: "",

            showErrorModal: false,
            errorMsg: '',
            valError: false
        };
    },
    validations () {
        return {
            form: {
                mobileNo: { required },
                otp: { required, minLength: minLength(6) },
                userName: { required, minLength: minLength(4) },
                userId: { required, minLength: minLength(4) },
                password: { required, minLength: minLength(8) }
            }
        }
    },
    computed: {
    formattedTime() {
      const minutes = Math.floor(this.seconds / 60);
      const remainingSeconds = this.seconds % 60;
      return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    }
  },

    methods: {

        startTimer() {
            this.seconds=120
      // Check if the timer is already running
      if (!this.timerRunning) {
        this.timerRunning = true;
        this.timerInterval = setInterval(() => {
          if (this.seconds > 0) {
            this.seconds--;
          } else {
            // Stop the timer when it reaches 0
            this.stopTimer();
          }
        }, 1000);

        
      }
    },
    stopTimer() {
      // Clear the timer interval to stop the timer
      clearInterval(this.timerInterval);
      this.timerRunning = false;
    },
    beforeDestroy() {
    // Clear the timer interval to avoid memory leaks
    clearInterval(this.timerInterval);
  },
        refreshData() {
            this.form = {
                code: 91,
                mobileNo: "",
                //otp: [],
                otp: "",
                userName: "",
                password: "",
                confirmPassword: "",
                referCode: ""
            },
                this.showValidationError = false,
                this.showErrorMobile = false,
                this.passwordValidation = false
        },
        sendOtpCall() {
            if (!this.loadingOtp) {
                if (this.form.mobileNo == "") {
                    this.showErrorModalFunc("Please enter mobile.");
                    this.showErrorMobile = true;
                }
                else {
                    this.sendOtpServiceCall();
                    //this.startTimer();
                }
            }
        },
        sendOtpServiceCall() {
                this.loadingOtp = true;
                let data = {
                    "mobileno": this.form.mobileNo,
                    "countryCode": this.form.code,
                };
                api.post(apiName.SEND_OTP, data).then(response => {
                    this.loadingOtp = false;
                    if (response.status == 200) {
                        if(response.data.status_code == 200){
                            this.successMsg = response.data.message;
                            this.showSuccessModalFunc("Otp sent successfully.");
                            this.startTimer();
                            /* this.showSuccessModal = true;
                            setTimeout(() => {
                                this.showSuccessModal = false;
                            }, 2000); */
                            window.scrollTo(0, 0);

                        } else {
                            this.showErrorModalFunc(response?.data?.message);
                        }
                        
                    } else {
                        this.showErrorModalFunc(response?.data?.data);

                    }
                        
                }).catch(error => {
                    this.loadingOtp = false;
                    this.showErrorModalFunc(error?.data?.message);
                });
        },
        // signUpCall() {
        //     if (!this.loadingSignUp) {
        //         if (this.form.mobileNo == "") {
        //             this.showErrorMobile = true;
        //         }
        //         else if (this.form.otp.toString().length != 6 || this.form.userName == '' || this.form.password == '') {
        //             this.showValidationError = true;
        //         }
        //         /* else if (this.form.password == '') {
        //             this.passwordValidation = true;
        //         } */
        //         else {
        //             this.signUpServiceCall();
        //         }
        //     }
        // },
        async signUpCall() {
            this.v$.form.$touch();
            if (!this.v$.form.$invalid) {
                this.loadingSignUp = true;
                /* const veriFyOtpRes = await this.verifyOtp();
                //return veriFyOtpRes;
                this.showSuccessModalFunc(veriFyOtpRes); */
                //return veriFyOtpRes;
            /*  if(!this.otpValidation){
                    this.showValidationError = true
                    return 
                } */
                let data = {
                    "userid": this.form.userId,
                    "username": this.form.userName,
                    "mobileno": this.form.mobileNo,
                    "password": this.form.password,
                    "otp": this.form.otp,
                    "refer_code": this.form.referCode,
                    "domain": window.location.host
                };

                api.post(apiName.REGISTER, data).then(response => {
                    this.loadingSignUp = false;
                    if (response) {
                        if (response.status == 200) {
                            if (!response.data.status) {
                                this.showErrorModalFunc(response?.data?.message);
                            }
                            else {
                                this.showSuccessModalFunc(response?.data?.message);
                                this.refreshData();
                                this.$router.push('/login')
                            }
                        }
                        else {
                            this.showErrorModalFunc(response?.data?.message);
                        }
                    }
                }).catch(error => {
                    this.loadingSignUp = false;
                    this.showErrorModalFunc(error[0]);
                });
            } else {
                this.showErrorModalFunc("Please fill the required fields");
                this.valError = true;
            }
        },
        getOtp() {
            return this.form.otp.toString().replaceAll(',', '');
        },
        async verifyOtp() {
            let data = {
                "mobile_number": this.form.mobileNo,
                "otp": this.form.otp
            };
            await api.post(apiName.VERIFY_OTP, data).then(response => {
                this.loadingSignUp = false;
                if (response) {
                    if (response.status == 200) {
                        if (response.data.success) {
                           this.otpValidation = response.data.success 
                        }
                    }
                }
            }).catch(error => {
                this.loadingSignUp = false;
                this.showErrorModalFunc(error[0]);
            });
        }

    },
    mounted() {
        document.body.classList.add('login-body-bg', 'inner-body');
    },
    unmounted() {
        document.body.classList.remove('login-body-bg', 'inner-body');
    }
};
</script>

<style scoped>
.invalid-otp .otp-warn{
    color: red;
}


</style>