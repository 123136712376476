<template>
    <section class="center-fix-wrapper">
        <div class="container">
            <div class="center-fix-sec">
                <div class="center-fix-con">
                    <img src="@/assets/images/something-wrong-icon.svg" alt="">
                    <div class="thm-heading">
                        <h2>Something went wrong</h2>
                        <p>Make sure WIFI or cellular data is turned on <br>
                            and then try again</p>
                    </div>
                    <div class="thm-modal-btn no-data-pages-btn">
                        <a href="#" class="thm-btn ">Go Back</a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "NoInternetConnection",
    mounted() {
        document.body.classList.add('inner-body', 'vh-100');
    },
    unmounted() {
        document.body.classList.remove('inner-body', 'vh-100');
    }
}
</script>