<template>
    <div class="offcanvas-wrapper">
        <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
            <div class="offcanvas-header">
                <div class="offcanvas-header-sec">
                    <div class="offcanvas-header-menu-con">
                        <div class="header-wrapper menu-header">
                            <div class="container">
                                <div class="header-sec">
                                    <div class="register-header-con">
                                        <div class="register-header-det">
                                            <div class="thm-heading">
                                                
                                                <h2>{{ userData?.name }}</h2>
                                                <p>Last Login: {{ userData?.last_login_at }} </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="header-left-sec">
                                        <div class="header-but-sec">
                                            <button type="button" class="btn-close" data-bs-dismiss="offcanvas"
                                                aria-label="Close" ref="sidemenuClose"></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- <div class="games-wrapper menu-wrapper">
                            <div class="container">
                                <div class="wallet-history-list-sec">
                                    <ul>

                                        <li @click="closeSideMenu()">
                                            <div class="border-right">
                                                <div class="bet-details-item ">
                                                    <div class="bet-details-right w-auto ">
                                                        <div class="flag-img">
                                                            <img src="@/assets/images/total-deposit.svg" alt="">
                                                        </div>
                                                    </div>
                                                    <div class="bet-details-left w-auto">
                                                        <div class=" thm-heading">
                                                            <h2>₹{{ userData?.balance }}</h2>
                                                            <h3>Wallet Fun point</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>

                                        <li @click="closeSideMenu()">
                                            <div>
                                                <div class="bet-details-item ">
                                                    <div class="bet-details-right w-auto ">
                                                        <div class="flag-img">
                                                            <img src="@/assets/images/total-withdraw.svg" alt="">
                                                        </div>
                                                    </div>
                                                    <div class="bet-details-left w-auto">
                                                        <div class=" thm-heading">
                                                            <h2 class="rejected-color">₹{{ Math.abs(userData?.exposure).toLocaleString("hi") }}</h2>
                                                            <h3>Exposure</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </div> -->

                        <!-- <div class="sub-sports-wrapper team-stats-sec horse-sub-header menu-wrapper">
                            <div class="container">
                                <div class="sports-sub-sec">
                                    <div class="sports-sub-list">
                                        <div class=" thm-heading text-start m-0">
                                            <p>Bonus Information</p>
                                        </div>
                                        <div class=" thm-heading text-end">
                                            <div class="more-icon">
                                                <a href="#" data-bs-target="#bonus-information" data-bs-toggle="modal">
                                                    <div class="info-con">
                                                        <img src="@/assets/images/info-list-con.svg" alt="">
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="madhur-box thm-heading available-sec">
                                        <div class="time-sec border-right ">
                                            <div class="time-con">
                                                <h3>Available Credits:- <span>{{ userData?.balance }}</span> </h3>
                                            </div>
                                        </div>
                                        <div class="time-sec justify-content-center">
                                            <div class="time-con">
                                                <h3>Net Exposure:- <span>{{ Math.abs(userData?.exposure).toLocaleString("hi") }}</span> </h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="madhur-box thm-heading available-sec">
                                        <div class="time-sec border-0">
                                            <div class="time-con check-sec">
                                                <h3>Play with Bonus </h3>
                                                <div class="form-switch m-0 p-0">
                                                    <input class="form-check-input mt-0" type="checkbox"
                                                        id="flexSwitchCheckDefault">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->

                    </div>

                </div>

            </div>
            <div class="offcanvas-body">

                <div class="sub-sports-wrapper game-tab-wrapper user-menu-tab">
                    <div class="container">
                        <div class="sports-sub-sec sports-inplay-tab">
                            <ul class="nav nav-pills sports-sub-list" id="pills-tab" role="tablist">
                                <li class="nav-item sports-sub-item thm-heading" role="presentation">
                                    <button class="nav-link active" id="pills-menu-tab" data-bs-toggle="pill"
                                        data-bs-target="#pills-menu" type="button" role="tab" aria-controls="pills-menu"
                                        aria-selected="false" tabindex="-1">
                                        <h3>Menu</h3>
                                    </button>
                                </li>
                                <li class="nav-item sports-sub-item thm-heading" role="presentation">
                                    <button class="nav-link" id="pills-game-tab" data-bs-toggle="pill"
                                        data-bs-target="#pills-game" type="button" role="tab" aria-controls="pills-game"
                                        aria-selected="false" tabindex="-1">
                                        <h3>Game</h3>
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="sports-inplay user-menu-tab-con">
                    <div class="user-menu-tab-list">
                        <div class="tab-content" id="pills-tabContent">
                            <div class="tab-pane fade show active" id="pills-menu" role="tabpanel"
                                aria-labelledby="pills-menu-tab">
                                <div class="games-wrapper">
                                    <div class="container">
                                        <div class="wallet-history-list-sec reference-setting">
                                            <ul>

                                                <!-- <li @click="closeSideMenu()">
                                                    <router-link :to="{ name: 'credit-reference'}">
                                                        <div class="wallet-history-list">
                                                            <div class="bet-details-item ">
                                                                <div class="reference-setting-con">
                                                                    <div class="bet-details-right w-auto ">
                                                                        <div class="menu-img">
                                                                            <img src="@/assets/images/credit-reference-icon.svg"
                                                                                alt="">
                                                                        </div>
                                                                    </div>
                                                                    <div class="bet-details-left w-auto">
                                                                        <div class=" thm-heading">
                                                                            <h3>Credit reference</h3>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </router-link>
                                                </li> -->

                                                <li>
                                                    <a href="#">
                                                        <div class="wallet-history-list">
                                                            <div class="bet-details-item ">
                                                                <div class="reference-setting-con">
                                                                    <div class="bet-details-right w-auto ">
                                                                        <div class="menu-img">
                                                                            <img src="@/assets/images/setting-icon.svg"
                                                                                alt="">
                                                                        </div>
                                                                    </div>
                                                                    <div class="bet-details-left w-auto">
                                                                        <div class=" thm-heading">
                                                                            <h3>Setting</h3>
                                                                        </div>
                                                                    </div>
                                                                    <div v-if="clickBetCheck && getClickBetValue()" data-bs-toggle="modal"
                                                                        data-bs-target="#clickBet">
                                                                        <label text="{{ stakeValue }}">{{stakeValue}}</label>
                                                                    </div>
                                                                    <div v-else data-bs-toggle="modal"
                                                                        data-bs-target="#clickBet">
                                                                        <label text="{{ stakeValue }}" :v-model="stakeValue"></label>
                                                                    </div>
                                    
                                                                </div>
                                                                <div class="madhur-box thm-heading available-sec m-0 p-0">
                                                                    <div class="time-sec border-0 pe-0">
                                                                        <div class="time-con check-sec">
                                                                            <div class="form-switch m-0 p-0">
                                                                                <input class="form-check-input mt-0"
                                                                                    type="checkbox"
                                                                                    id="flexSwitchCheckDefault" @change="betCheckChangeFunc()" v-model="clickBetCheck">
                                                                            </div>
                                                                              <a style="display: none;" ref="open_click_bet_value" data-bs-toggle="modal"
                                        data-bs-target="#clickBet"></a>
                                                                            <h3>On click bet</h3>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </li>


                                            </ul>
                                        </div>
                                        <div class="wallet-history-list-sec">
                                            <ul>

                                                <!-- <li data-bs-dismiss="offcanvas">
                                                    <router-link :to="{name: 'bonus'}">
                                                        <div class="wallet-history-list">
                                                            <div class="bet-details-item ">
                                                                <div class="bet-details-right w-auto ">
                                                                    <div class="menu-img">
                                                                        <img src="@/assets/images/bonus-icon.svg" alt="">
                                                                    </div>
                                                                </div>
                                                                <div class="bet-details-left w-auto">
                                                                    <div class=" thm-heading">
                                                                        <h3>Bonus</h3>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </router-link>
                                                </li> -->

                                                <li @click="closeSideMenu()">
                                                    <router-link :to="{name: 'notifications'}">
                                                        <div class="wallet-history-list">
                                                            <div class="bet-details-item ">
                                                                <div class="bet-details-right w-auto ">
                                                                    <div class="menu-img">
                                                                        <img src="@/assets/images/notification-icon.svg"
                                                                            alt="">
                                                                    </div>
                                                                </div>
                                                                <div class="bet-details-left w-auto">
                                                                    <div class=" thm-heading">
                                                                        <h3>Notification</h3>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </router-link>
                                                </li>

                                               <!--  <li @click="closeSideMenu()">
                                                    <router-link :to="{name: 'ReferEarn'}">
                                                        <div class="wallet-history-list">
                                                            <div class="bet-details-item ">
                                                                <div class="bet-details-right w-auto ">
                                                                    <div class="menu-img">
                                                                        <img src="@/assets/images/statement-icon.svg" alt="">
                                                                    </div>
                                                                </div>
                                                                <div class="bet-details-left w-auto">
                                                                    <div class=" thm-heading">
                                                                        <h3>Refer and Earn</h3>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </router-link>
                                                </li> -->

                                                <li @click="closeSideMenu()">
                                                    <router-link :to="{name: 'bets', params: {type: 'pnl'}}">
                                                        <div class="wallet-history-list">
                                                            <div class="bet-details-item ">
                                                                <div class="bet-details-right w-auto ">
                                                                    <div class="menu-img">
                                                                        <img src="@/assets/images/profit-loss-icon.svg"
                                                                            alt="">
                                                                    </div>
                                                                </div>
                                                                <div class="bet-details-left w-auto">
                                                                    <div class=" thm-heading">
                                                                        <h3>Profit & Loss</h3>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </router-link>
                                                </li>

                                                <li @click="closeSideMenu()">
                                                    <router-link :to="{name: 'bets', params: {type: 'open'}}">
                                                        <div class="wallet-history-list">
                                                            <div class="bet-details-item ">
                                                                <div class="bet-details-right w-auto ">
                                                                    <div class="menu-img">
                                                                        <img src="@/assets/images/menu-open-bets-icon.svg"
                                                                            alt="">
                                                                    </div>
                                                                </div>
                                                                <div class="bet-details-left w-auto">
                                                                    <div class=" thm-heading">
                                                                        <h3>Open Bets </h3>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </router-link>
                                                </li>

                                                <li @click="closeSideMenu()">
                                                    <router-link :to="{name: 'bets', params: {type: 'settled'}}">
                                                        <div class="wallet-history-list">
                                                            <div class="bet-details-item ">
                                                                <div class="bet-details-right w-auto ">
                                                                    <div class="menu-img">
                                                                        <img src="@/assets/images/settled-bets-icon.svg"
                                                                            alt="">
                                                                    </div>
                                                                </div>
                                                                <div class="bet-details-left w-auto">
                                                                    <div class=" thm-heading">
                                                                        <h3>Settled bets</h3>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </router-link>
                                                </li>



                                                <li @click="closeSideMenu()">
                                                    <router-link :to="{name: 'favourites'}">
                                                        <div class="wallet-history-list">
                                                            <div class="bet-details-item ">
                                                                <div class="bet-details-right w-auto ">
                                                                    <div class="menu-img">
                                                                        <img src="@/assets/images/favorite-menu.svg" alt="">
                                                                    </div>
                                                                </div>
                                                                <div class="bet-details-left w-auto">
                                                                    <div class=" thm-heading">
                                                                        <h3>Favorite</h3>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </router-link>
                                                </li>

                                                <li @click="closeSideMenu()">
                                                    <router-link :to="{name: 'MyMarket', params: {type: 'sports'}}">
                                                        <div class="wallet-history-list">
                                                            <div class="bet-details-item ">
                                                                <div class="bet-details-right w-auto ">
                                                                    <div class="menu-img">
                                                                        <img src="@/assets/images/Market-icon.svg" alt="">
                                                                    </div>
                                                                </div>
                                                                <div class="bet-details-left w-auto">
                                                                    <div class=" thm-heading">
                                                                        <h3>My Market</h3>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </router-link>
                                                </li>

                                                <li @click="closeSideMenu()">
                                                    <router-link :to="{name: 'bets', params: {type: 'statement'}}">
                                                        <div class="wallet-history-list">
                                                            <div class="bet-details-item ">
                                                                <div class="bet-details-right w-auto ">
                                                                    <div class="menu-img">
                                                                        <img src="@/assets/images/menu-statement-icon.svg" alt="">
                                                                    </div>
                                                                </div>
                                                                <div class="bet-details-left w-auto">
                                                                    <div class=" thm-heading">
                                                                        <h3>Statement</h3>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </router-link>
                                                </li>


                                                <li @click="closeSideMenu()">
                                                    <!-- <router-link :to="{ name: 'edit-stack'}"> -->
                                                    <a href="#" data-bs-toggle="modal" data-bs-target="#editeModal" @click="getUserStack()">

                                                        <div class="wallet-history-list">
                                                            <div class="bet-details-item ">
                                                                <div class="bet-details-right w-auto ">
                                                                    <div class="menu-img">
                                                                        <img src="@/assets/images/edit-stack-icon.svg" alt="">
                                                                    </div>
                                                                </div>
                                                                <div class="bet-details-left w-auto">
                                                                    <div class=" thm-heading">
                                                                        <h3>Edit Stack</h3>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                    <!-- </router-link> -->
                                                </li>

                                                <li @click="closeSideMenu()">
                                                    <router-link :to="{ name: 'rule'}">
                                                        <div class="wallet-history-list">
                                                            <div class="bet-details-item ">
                                                                <div class="bet-details-right w-auto ">
                                                                    <div class="menu-img">
                                                                        <img src="@/assets/images/rule-text-icon.svg" alt="">
                                                                    </div>
                                                                </div>
                                                                <div class="bet-details-left w-auto">
                                                                    <div class=" thm-heading">
                                                                        <h3>Rule</h3>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </router-link>
                                                </li>

                                                <li @click="closeSideMenu()">
                                                    <router-link :to="{ name: 'changepassword'}">
                                                            <div class="wallet-history-list">
                                                            <div class="bet-details-item ">
                                                                <div class="bet-details-right w-auto ">
                                                                    <div class="menu-img">
                                                                        <img src="@/assets/images/change-pass-icon.svg"
                                                                            alt="">
                                                                    </div>
                                                                </div>
                                                                <div class="bet-details-left w-auto">
                                                                    <div class=" thm-heading">
                                                                        <h3>Change Pass..</h3>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </router-link>
                                                </li>


                                            </ul>
                                        </div>
                                       
                                    </div>
                                </div>
                                <div class="games-wrapper">
                                    <div class="container">
                                        <div class="rejected-sec">
                                            <div class="edit-btn">
                                                <button class="edit-thm-btn mb-0 rejected-bg" @click="logoutServiceCall()">
                                                    <span class="rejected-color">Logout</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="pills-game" role="tabpanel" aria-labelledby="pills-game-tab">
                                <div class="games-wrapper menu-game-tab">
                                    <div class="container">
                                        <div class="wallet-history-list-sec">
                                            <ul>

                                                <li  @click="closeSideMenu()">
                                                    <router-link :to="{ name: 'Sports', params: { type: 'inplay' } }">

                                                        <div class="wallet-history-list">
                                                            <div class="bet-details-item ">

                                                                <div class="bet-details-left w-auto">
                                                                    <div class=" thm-heading">
                                                                        <h3>Inplay</h3>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                   </router-link>
                                                </li>
                                                   <template v-if="getGameList?.custom">
                                                        <li  @click="closeSideMenu()" v-for="sports in getGameList.custom" :key="sports.id" >
                                                            <router-link  :to="{ name: 'Sports', params: { type: sports.name, id: sports.id } }">
                                                                <div class="wallet-history-list">
                                                                    <div class="bet-details-item ">
                                                                        <div class="bet-details-left w-auto">
                                                                            <div class="thm-heading">
                                                                                <h3 class="text-capitalize">{{ sports.name }}</h3>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </router-link>
                                                        </li>
                                                    </template>
                                                    <template  v-if="getGameList?.non_custom">
                                                        <li  @click="closeSideMenu()" v-for="sportsNC in getGameList.non_custom" :key="sportsNC.id">
                                                            <router-link :to="{ name: 'Sports', params: { type: sportsNC.name, id: sportsNC.id } }">
                                                                <div class="wallet-history-list">
                                                                    <div class="bet-details-item ">
                                                                        <div class="bet-details-left w-auto">
                                                                            <div class=" thm-heading">
                                                                                <h3 class="text-capitalize">{{ sportsNC.name }}</h3>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </router-link>
                                                        </li>
                                                    </template>
                                                    
                                                   
                                               <!--  <li>
                                                   <router-link :to="{ name: 'Sports', params: { type: 'cricket', id: 4 } }">
                                                        <div class="wallet-history-list">
                                                            <div class="bet-details-item ">

                                                                <div class="bet-details-left w-auto">
                                                                    <div class=" thm-heading">
                                                                        <h3>Cricket</h3>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </router-link>
                                                </li> -->

                                               <!--  <li>
                                                    <a href="#">
                                                        <div class="wallet-history-list">
                                                            <div class="bet-details-item ">
                                                                <div class="bet-details-left w-auto">
                                                                    <div class=" thm-heading">
                                                                        <h3>virtual-sports</h3>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </li>
 -->
                                               <!--  <li>
                                                    <router-link :to="{ name: 'Sports', params: { type: 'soccer', id: 2 } }">

                                                        <div class="wallet-history-list">
                                                            <div class="bet-details-item ">
                                                                <div class="bet-details-left w-auto">
                                                                    <div class=" thm-heading">
                                                                        <h3>Soccer</h3>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </router-link>
                                                </li> -->

                                                <!-- <li>
                                                    <router-link :to="{ name: 'Sports', params: { type: 'tennis', id: 2 } }">

                                                        <div class="wallet-history-list">
                                                            <div class="bet-details-item ">
                                                                <div class="bet-details-left w-auto">
                                                                    <div class=" thm-heading">
                                                                        <h3>Tennis</h3>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </router-link>
                                                </li> -->

                                                <li  @click="closeSideMenu()">
                                                    <router-link :to="{ name: 'games', params: { type: 'virtual-sport' } }">
                                                        <div class="wallet-history-list">
                                                            <div class="bet-details-item ">
                                                                <div class="bet-details-left w-auto">
                                                                    <div class=" thm-heading">
                                                                        <h3>Virtual Sports</h3>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </router-link>
                                                </li>

                                                <li  @click="closeSideMenu()">
                                                    <router-link :to="{ name: 'games', params: { type: 'live-casino' } }">
                                                        <div class="wallet-history-list">
                                                            <div class="bet-details-item ">
                                                                <div class="bet-details-left w-auto">
                                                                    <div class=" thm-heading">
                                                                        <h3>Live Casino</h3>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </router-link>
                                                </li>

                                                <li  @click="closeSideMenu()">
                                                     <router-link :to="{ name: 'sports-book' }">
                                                        <div class="wallet-history-list">
                                                            <div class="bet-details-item ">
                                                                <div class="bet-details-left w-auto">
                                                                    <div class=" thm-heading">
                                                                        <h3>Sports Book</h3>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </router-link>
                                                </li>

                                                <li  @click="closeSideMenu()">
                                                     <router-link :to="{ name: 'matka' }">
                                                        <div class="wallet-history-list">
                                                            <div class="bet-details-item ">
                                                                <div class="bet-details-left w-auto">
                                                                    <div class=" thm-heading">
                                                                        <h3>Matka</h3>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </router-link>
                                                </li>



                                                <!-- <li>
                                                    <a href="#">
                                                        <div class="wallet-history-list">
                                                            <div class="bet-details-item ">
                                                                <div class="bet-details-left w-auto">
                                                                    <div class=" thm-heading">
                                                                        <h3>Horsenrace</h3>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </li> -->

                                                <!-- <li>
                                                    <a href="#">
                                                        <div class="wallet-history-list">
                                                            <div class="bet-details-item ">
                                                                <div class="bet-details-left w-auto">
                                                                    <div class=" thm-heading">
                                                                        <h3>Grey Hound</h3>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </li> -->

                                            </ul>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <editStackModal :stackSetting="stackSetting"></editStackModal>
</template>

<script>
import { AUTH_TOKEN } from '@/shared/constants/cookies-const';
import api from '@/shared/services/api';
import * as apiName from '@/shared/services/urls';
import editStackModal from "@/modules/wallet/components/EditStakeModal.vue";
import { Modal } from 'bootstrap';
export default {
    name: "SideMenu",
    props:['allGames'],
    data() {
        return {
            userData : this.$store?.state?.auth?.user,
            stackSetting: {
                cn1: '',
                cn2: '',
                cn3: '',
                cn4: '',
                cn5: '',
                cn6: '',
                cv1: '',
                cv2: '',
                cv3: '',
                cv4: '',
                cv5: '',
                cv6: '',
            },
            clickBetCheck: false,      
            stakeValue:'' 
        }
    },
    computed: {
       
        getGameList() {
            return this.$store?.getters?.getGameList;
        },
        clickStack() {
            return this.stakeValue;
        }

    },
    components: {
        editStackModal
       
    },
    mounted() {
        if (this.getClickBetValue()) {
            this.clickBetCheck = true
        }
        this. getClickBetSelectedValue() 
    },
    methods: {
        getClickBetValue() {
            let clickBetValue=localStorage.getItem('setClickBet')
            
            return clickBetValue ? clickBetValue : null;
        },

        getClickBetSelectedValue() {
            let inputValues = JSON.parse(this.getClickBetValue());
            console.log("Click Value",inputValues)

            // return inputValues;
            if (inputValues){
                const selectedValues =inputValues;
                this.stakeValue=selectedValues;
                console.log('==',this.stakeValue, '====',selectedValues);
            }
            //     return inputValues.filter(item => item.isSelected)[0].value;
        },
        getKFormatter(num) {
            return kFormatter(num);
        },
        checkAuth() {
            return this.$store?.getters?.isAuthenticated
        },
        betCheckChangeFunc() {
            if (this.clickBetCheck) {
                this.$refs.open_click_bet_value.click();
            }
            else {
                localStorage.removeItem('setClickBet');
                this.clickBetCheck = false
            }
        },
        closeModal() {
            this.$refs.close_button.click();
        },
        logoutServiceCall() {
            let headers = {
                //'Authorization': `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
                'Authorization': `Bearer ${this.$cookies.get(AUTH_TOKEN)}`

            }

            api.post(apiName.LOGOUT_API, {},{ headers: headers }).then(response => {
                this.loading = false;
                if (response) {
                    if (response.status == 200) {
                        if (!response?.data?.status) {
                            this.$emit('error-modal', response?.data?.message??'')
                        }
                        else {
                            this.$emit('success-modal', response?.data?.message??'');
                            this.$cookies.keys().forEach(cookie => this.$cookies.remove(cookie))
                            this.$store.dispatch('resetAllState');
                            location.reload();
                            this.$router.push('/');
                            this.closeSideMenu();
                        }
                    }
                    
                }
            }).catch(error => {
                this.loading = false;
                if (error) {
                    this.$cookies.keys().forEach(cookie => this.$cookies.remove(cookie))
                    this.$store.dispatch('resetAllState');
                    ///sessionStorage.removeItem("HOME_API_DATA")
                    location.reload();
                    this.$router.push('/');
                    this.closeSideMenu();
                   /*  this.$emit('success-modal', response?.data?.message??'');
                    this.$cookies.keys().forEach(cookie => this.$cookies.remove(cookie))
                    this.$store.dispatch('resetAllState');
                    sessionStorage.removeItem("HOME_API_DATA")
                    location.reload();
                    this.$router.push('/');
                    this.closeSideMenu();  */
                    ///this.$emit('error-modal', error[0]);
                }
            });
        },
        closeSideMenu() {
            this.$refs.sidemenuClose.click();
        },
        getUserStack(){
            let headers = {'Authorization': `Bearer ${this.$cookies.get(AUTH_TOKEN)}`  }
            api.post(apiName.STACK_SETTING, {}, { headers: headers }).then(response => {

                if (response.status == 200) {
                    if (response.data.status_code != 200) {
                        this.showErrorModalFunc(response.data.debug[0]);
                    }
                    else {
                        let stackList = response.data.data.keyformat;
                        if(stackList.length > 0){
                            this.stackSetting.cn1 = stackList[0].key;
                            this.stackSetting.cn2 = stackList[1].key;
                            this.stackSetting.cn3 = stackList[2].key;
                            this.stackSetting.cn4 = stackList[3].key;
                            this.stackSetting.cn5 = stackList[4].key;
                            this.stackSetting.cn6 = stackList[5].key;

                            this.stackSetting.cv1 = stackList[0].val;
                            this.stackSetting.cv2 = stackList[1].val;
                            this.stackSetting.cv3 = stackList[2].val;
                            this.stackSetting.cv4 = stackList[3].val;
                            this.stackSetting.cv5 = stackList[4].val;
                            this.stackSetting.cv6 = stackList[5].val;
                        }

                    }
                } else {
                    this.showErrorModalFunc(response?.data);
                }
            }).catch(error => {
                if (error) {
                    this.showErrorModalFunc(error?.data?.message);
                }
            });
        },

    }
};
</script>