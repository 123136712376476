


const RuleModule = () => import(/* webpackChunkName: "rule-module" */ './views/Module.vue');
const Rule = () => import(/* webpackChunkName: "rule" */ './views/Rule.vue');


const RuleRoutes = {
    path: '/',
    component: RuleModule,
    children: [
        {
            path: 'rule',
            name: 'rule',
            component: Rule,
        }
    ],
}

export default RuleRoutes;
