const state = {
    matkaEventData: 0,
};
const getters = {
    // getContest_event: state => state.matkaEventData,
};
const actions = {
    setMatakaEventData({ commit},matka) {
        commit('setMatkaEventData', matka)
    }
};
const mutations = {
    setMatkaEventData(state, matka) {
        state.matkaEventData = matka
    },
};
export default {
    state,
    getters,
    actions,
    mutations
};