const state = {
    Contest_event_name: 0,
};
const getters = {
    // getContest_event: state => state.Contest_event_name,
};
const actions = {

};
const mutations = {
    setContest_event_name(state, name) {
        state.Contest_event_name = name
    },
};
export default {
    state,
    getters,
    actions,
    mutations
};