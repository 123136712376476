<template>
    <div class="inner-body login-body-bg">

        <div class="register-header-wrapper">

            <div class="register-header-sec">
                <div class="container">
                    <div class="register-header-con">
                        <div class="black-arrow">
                            <a href="login.html"><img src="@/assets/images/back-arrow.svg" alt=""></a>
                        </div>
                        <div class="register-header-det">
                            <div class="thm-heading">
                                <h3>Login</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="register-header-banner">
                <div class="container">
                    <div class="register-banner">
                        <div class="register-banner-logo">
                            <a href="#">
                                <img src="@/assets/images/logo.png" alt="">
                            </a>
                        </div>
                        <div class="thm-heading">
                            <h2>Create New Password</h2>
                            <p>your new password must be different from previous used passwords.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-wrapper">
                <div class="container">
                    <div class="form-sec">
                        <form>
                            <div class="form-con">


                                <div class="form-con-input">
                                    <label for="exampleInputEmail1" class="form-label">Password </label>
                                    <div class="input-select">
                                        <input type="password" class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Enter here" v-model="form.password">

                                        <div class="left-input-sel">
                                            <div class="left-input-img">
                                                <img class="user-in-img show" src="@/assets/images/lock-icon.svg" alt="">
                                            </div>
                                        </div>

                                        <div class="right-input-sel">
                                            <div class="left-input-img">
                                                <button type="button" class="border-0 bg-transparent"><img class="user-in-img show"
                                                        src="@/assets/images/eye-icon.svg" alt=""></button>

                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div class="form-con-input">
                                    <label for="exampleInputEmail1" class="form-label">Confirm Password </label>
                                    <div class="input-select">
                                        <input type="password" class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Enter here"
                                            v-model="form.confirmPassword">

                                        <div class="left-input-sel">
                                            <div class="left-input-img">
                                                <img class="user-in-img show" src="@/assets/images/lock-icon.svg" alt="">
                                            </div>
                                        </div>

                                        <div class="right-input-sel">
                                            <div class="left-input-img">
                                                <button type="button" class="border-0 bg-transparent"><img class="user-in-img show"
                                                        src="@/assets/images/eye-icon.svg" alt=""></button>

                                            </div>
                                        </div>
                                    </div>

                                </div>


                                <div class="form-con-input register-btn ">
                                    <div class="form-btn ">
                                        <a href="javascript:void(0);" class="thm-btn"
                                            @click.prevent="newPasswordCall()">Reset</a>
                                    </div>
                                    <div class="forgot-sub-con text-center">
                                        <h5>Already have an account?
                                            <router-link :to="{ name: 'Login' }">
                                                <span>Login?</span>
                                            </router-link>
                                        </h5>
                                    </div>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import api from '../services/api';
import * as apiName from '../services/urls';
export default {
    name: 'NewPassword',
    mounted() {
        document.body.classList.add('login-body-bg', 'inner-body');
    },
    unmounted() {
        document.body.classList.remove('login-body-bg', 'inner-body');
    },
    data() {
        return {
            form: {
                password: '',
                confirmPassword: ''
            },
            showErrorMobile: false,
            showValidationError: false
        }
    },
    methods: {
        refreshData() {
            this.form = {
                password: '',
                confirmPassword: ''
            };
            this.showErrorMobile = false
            this.showValidationError = false

        },
        newPasswordCall() {
            this.passwordValidation = false;
            if (this.form.password == "" || this.form.confirmPassword == "") {
                this.showErrorModalFunc("Please enter password and confirm password.");
            }
            else if (this.form.password != this.form.confirmPassword) {
                this.showErrorModalFunc("Password and confirm password does not match.");
            }
            else {
                if(localStorage.getItem('mobile_number')){
                    this.newPassServiceCall();
                } else {
                    this.showErrorModalFunc("Session expired !");
                    this.refreshData();
                    this.$router.push('/')
                }
            }
        },
        newPassServiceCall() {
            let data = {
                "password": this.form.password,
                "password_confirmation": this.form.confirmPassword,
                'mobile_number': localStorage.getItem('mobile_number')
            };
            api.patch(apiName.RESET_PASSWORD, data).then(response => {
                this.loadingUpdate = false;
                if (response) {
                    if (response.status == 200) {
                        if (response.data.data.status) {
                            this.showSuccessModalFunc(response.data.message);
                            localStorage.removeItem('mobile_number')
                            this.refreshData();
                            this.$router.push('/')
                        }
                        else {
                            this.showErrorModalFunc(response.data.message);
                            
                        }
                    }
                }
            }).catch(error => {
                this.loadingUpdate = false;
                if (error) {
                    this.showErrorModalFunc(error?.data?.message);
                }
            });
        }
    }

}
</script>

<style></style>