const WalletModule = () => import(/* webpackChunkName: "wallet-module" */ './views/Module.vue');
const Wallet = () => import(/* webpackChunkName: "wallet" */ './views/Wallet.vue');
const WalletDeposit = () => import(/* webpackChunkName: "wallet-deposit" */ './views/WalletDeposit.vue');
const WalletHistory = () => import(/* webpackChunkName: "wallet-history" */ './views/WalletHistory.vue');
const WalletHistoryDetail = () => import(/* webpackChunkName: "wallet-history-detail" */ './views/WalletHistoryDetail.vue');
// const PaymentMethod = () => import(/* webpackChunkName: "payment-method" */ './views/PaymentMethod.vue');
const AddBankAccount = () => import(/* webpackChunkName: "add-account" */ './views/AddBankAccount.vue');
const WalletWithdraw = () => import(/* webpackChunkName: "wallet-withdraw" */ './views/WalletWithdraw.vue');
const BankDetails = () => import(/* webpackChunkName: "bank-details" */ './views/BankDetails.vue');


const WalletRoutes = {
    path: '/',
    component: WalletModule,
    children: [
      {
        path: 'wallet',
        name: 'Wallet',
        component: Wallet,
        meta: { requiresAuth: true },
      },
      {
        path: 'wallet-deposit',
        name: 'wallet-deposit',
        component: WalletDeposit,
      },
      {
        path: 'wallet-history',
        name: 'wallet-history',
        component: WalletHistory,
      },
      {
        path: 'wallet-history-detail/:type',
        name: 'wallet-history-detail',
        component: WalletHistoryDetail,
      },
      // {
      //   path: 'payment-method',
      //   name: 'payment-method',
      //   component: PaymentMethod,
      // },
      {
        path: 'new-payment/:itemDetail',
        name: 'AddBankAccount',
        component: AddBankAccount,
        meta: { requiresAuth: true },
      },
      {
        path: 'wallet-withdraw',
        name: 'wallet-withdraw',
        component: WalletWithdraw,
        meta: { requiresAuth: true },
      },
      {
        path: 'bank-details',
        name: 'BankDetails',
        component: BankDetails,
        meta: { requiresAuth: true },
      }
    ],
}

export default WalletRoutes;