const MyMarketModule = () => import(/* webpackChunkName: "my-market-module" */ './views/Module.vue');
const MyMarket = () => import(/* webpackChunkName: "my-market" */ './views/MyMarket.vue');

const MyMarketRoutes = {
    path: '/',
    component: MyMarketModule,
    children: [
        {
            path: 'my-market/:type',
            name: 'MyMarket',
            component: MyMarket
        },
        // {
        //     path: 'my-market/:type',
        //     name: 'matka',
        //     component: MyMarket 
        // }
    ],
}

export default MyMarketRoutes;