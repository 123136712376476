
import { createRouter, createWebHashHistory,createWebHistory  } from "vue-router";
import store from "@/store";
const Home = () => import("../views/Home.vue");

import CricketFightRoutes from "@/modules/cricket-fight";
import FavouriteRoutes from "@/modules/favourites";
import SportsRoutes from "@/modules/sports";
import VirtualSportRoutes from "@/modules/virtual-sport";
import SportBookRoutes from "@/modules/sports-book";
import WalletRoutes from "@/modules/wallet";
import MatkaRoutes from "@/modules/matka";
import RaceRoutes from "@/modules/race";
import GamesRoutes from "@/modules/games";
import CasinoGamesRoutes from "@/modules/casino-game";
import SearchRoutes from "@/modules/search";
import BetsRoutes from "@/modules/bets";
import NotificationsRoutes from "@/modules/notification";
import BonusRoutes from "@/modules/bonus";
import MyMarketRoutes from "@/modules/my-market";
import ReferEarnRoutes from "@/modules/refer-earn";
import AccountRoutes from "@/modules/account";
import RuleRoutes from "@/modules/rule";
import CreditReferenceRoutes from "@/modules/credit-reference";
import EditStackRoutes from "@/modules/edit-stack"

import Login from '@/modules/authorization/components/Login.vue'
import SignUp from '@/modules/authorization/components/SignUp.vue'
import ForgetPassword from '@/modules/authorization/components/ForgetPassword.vue'
import NewPassword from '@/modules/authorization/components/NewPassword.vue'

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
        children: [
            {
                path: '/inplay',
                name: 'Inplay',
            },
        ]
    },
    {
        path: "/login",
        name: "Login",
        component: Login
    },
    {
        path: "/register",
        name: "SignUp",
        component: SignUp
    },
    {
        path: "/forgot-password",
        name: "ForgetPassword",
        component: ForgetPassword
    },
    {
        path: "/new-password",
        name: "NewPassword",
        component: NewPassword
    },
    CricketFightRoutes,
    FavouriteRoutes,
    SportsRoutes,
    VirtualSportRoutes,
    SportBookRoutes,
    WalletRoutes,
    MatkaRoutes,
    RaceRoutes,
    GamesRoutes,
    SearchRoutes,
    BetsRoutes,
    NotificationsRoutes,
    BonusRoutes,
    MyMarketRoutes,
    ReferEarnRoutes,
    AccountRoutes,
    RuleRoutes,
    CreditReferenceRoutes,
    EditStackRoutes,
    CasinoGamesRoutes

];


const router = createRouter({
  hashbang: false,
  transitionOnLoad: true,
  root: "/",
  mode: "history",
  history: createWebHistory(),
  routes,
});


router.beforeEach((to, from, next) => {
    const isLoggedIn = store.getters.isAuthenticated;

    if (to.path === '/login' && isLoggedIn) {
        next('/');
    }
    else if (to.matched.some(record => record.meta.requiresAuth) && !isLoggedIn) {
        next({ name: 'Login' });
    }
    else {
        next();
    }

})

export default router;
