<template>
    <div class="footer-fix">
        <div class="footer-sec">
            <div class="footer-item" :class="{ show: ($route.name == 'Home' && $route.params.type != 'inplay') }">
                <router-link :to="{name: 'Home'}">
                    <div class="footer-con">
                        <div class="footer-con-img">
                            <img src="@/assets/images/fo-home-icon.svg" alt="">
                        </div>
                        <span>Home</span>
                    </div>
                </router-link>
            </div>
            <div class="footer-item" :class="{ show: $route.name == 'Inplay' }">
                <router-link :to="{ name: 'Sports', params: { type: 'inplay' } }">
                    <div class="footer-con">
                        <div class="footer-con-img">
                            <img src="@/assets/images/play-circle.svg" alt="">
                        </div>
                        <span>InPlay</span>
                    </div>
                </router-link>
            </div>

            <div class="footer-item cricket-fight-item" :class="{ show: $route.name == 'cricket-fight' }">
                <router-link :to="{ name: 'Sports', params: { type: 'cricket', id: 4 } }">
                    <div class="footer-con">
                        <div class="footer-con-img">
                            <img src="@/assets/images/cricket-fight-icon.svg" alt="">
                        </div>
                        <span>Cricket Fight</span>
                    </div>
                </router-link>
            </div>

            <div class="footer-item" :class="{ show: $route.name == 'games' && $route.params.type == 'LIVE_CASINO' }">
                <router-link :to="{name: 'games', params: {type: 'LIVE_CASINO'}}">
                    <div class="footer-con">
                        <div class="footer-con-img">
                            <img src="@/assets/images/foo-Club.svg" alt="">
                        </div>
                        <span>Casino</span>
                    </div>
                </router-link>
            </div>

            <div class="footer-item" :class="{ show: $route.name == 'bets' }">
                <router-link :to="{name: 'bets', params: {type: 'open'}}">
                    <div class="footer-con">
                        <div class="footer-con-img">
                            <img src="@/assets/images/pie-chart.svg" alt="">
                        </div>
                        <span>Open Bet</span>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>


<script>

export default {
    name: "Footer",
    components: {
    },
    methods: {
        checkIsLogin() {
            return this.$store.getters.isAuthenticated;
        }
    },
};
</script>

<style scoped>
.footer-list-sec {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
</style>