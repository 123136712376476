<template>
    <section class="toster-wrapper ">
        <div class="toster-fix">
            <div class="withdraw-sec">

                <div class="recent-accounts-con">
                    <div class="withdraw-con wrong-bg border-0">
                        <div class="withdraw-img">
                            <img src="@/assets/images/wrong-icon.svg" alt="">
                        </div>
                        <div class="withdraw-img-con thm-heading">
                            <h2>wrong !</h2>
                            <h3>{{ message }}</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: "ErrorModal",
    props: ['message'],
}
</script>

<style>
.wrong-bg {
    background-color: var(--error-color) !important;

}
</style>