<template>
    <div class="preloader d-flex align-items-center justify-content-center">
        <div class="cssload-container">
            <div class="cssload-loading"><i></i><i></i><i></i><i></i></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Loader'

}
</script>

<style></style>