<template>
	<header class="header-wrapper"
		:class="{ 'border-0': $route.name == 'Wallet', 'inner-search-header': $route.name == 'search' }">
		<div class="container">
			<div class="header-sec">
				<div class="logo-sec" v-if="checkForBackArrow()">
					<div class="offcanvas-sec" @click="!checkIsLogin ? $router.push('/login') : ''">
						<a data-bs-toggle="offcanvas" href="#offcanvasExample" role="button"
							aria-controls="offcanvasExample">
							<img src="@/assets/images/menu-icon.svg" alt="">
						</a>
					</div>
					<div class="logo-img">
						<a href="#">
							<img src="@/assets/images/logo.png" alt="">
						</a>
					</div>
				</div>
				<div class="register-header-con" v-else>
					<div class="black-arrow">
						<a href="javascript:void(0);" @click="$router.back()"><img src="@/assets/images/back-arrow.svg"
								alt=""></a>
						<!-- <router-link :to="{ name: 'Home' }"><img src="@/assets/images/back-arrow.svg" alt=""></router-link> -->
					</div>
					<div class="register-header-det">
						<div class="thm-heading">
							<h3>{{ getTitle() }}</h3>
						</div>
					</div>
				</div>
				<div class="header-left-sec">
					<div class="header-but-sec" v-if="!checkIsLogin">
						<router-link :to="'/login'" class="thm-btn thm-boder-btn">Login</router-link>
						<router-link :to="'/register'" class="thm-btn">join</router-link>
					</div>
					<div v-else>
						<div class="header-but-sec" v-if="showWallet()">
							<router-link :to="{ name: 'wallet-deposit' }" href="#" class="thm-btn">Deposit</router-link>
							<router-link :to="{ name: 'Wallet' }" class="thm-btn wallet-btn">
								<div class="wallet-sec">
									<div class="wallet-img"><img src="@/assets/images/wallet-icon.svg" alt=""></div>
								
								
									<div class="thm-heading" v-for="(item, index) in $store.state.auth.userProfile" :key="index">
								
										<!-- <h2>{{ $store.state.auth.user.balance.toLocaleString("hi") }}</h2> -->
										<h2>{{ Math.abs(item.balance) }}</h2>
									</div>
								</div>
								<div class="wallet-sub-con" v-for="(item, index) in $store.state.auth.userProfile" :key="index">
									<!-- <span>EXP : {{ Math.abs($store.state.auth.user.exposure).toLocaleString("hi") }}</span> -->
									<!-- {{ item.exposure }} -->
									<span>EXP : {{ Math.abs(item.exposrue) }}</span>
								</div>
							</router-link>
						</div>
					</div>
				</div>
			</div>
		</div>
	</header>
	<SideMenu @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" />
</template>
 
<script >

import SideMenu from "./SideMenu.vue";

export default {
	name: "Header",
	components: {
		SideMenu
	},
	data() {
		return {
			cricketFight_eventName: JSON.parse(localStorage.getItem('crick_fight_match')) ? JSON.parse(localStorage.getItem('crick_fight_match')).event_name : '',
			myContest_eventName: JSON.parse(localStorage.getItem('my_contest')) ? JSON.parse(localStorage.getItem('my_contest')).event_name : '',
		}
	},
	created() {
	},
	computed: {
		checkIsLogin() {
			return this.$store?.getters?.isAuthenticated;
		}
	},
	methods: {
		checkForBackArrow() {
			let routeName = this.$route.name;
			let routeType = this.$route.params.type;
			switch (routeName) {
				//checking for show three dot menu
				case 'Home':
				case 'Inplay':
					return true;
				case 'games':
					return routeType == 'GAMES';
				default:
					return false
			}
		},
		getTitle() {
			let routeName = this.$route.name;
			if (routeName == 'Sports') {
				let routeType = this.$route.params.type;
				switch (routeType) {
					case 'CRICKET':
						return 'Cricket';
					case 'SOCCER':
						return 'Soccer';
					case 'TENNIS':
						return 'Tennis';
					case 'ASSEMBLY_ELECTION':
						return 'Assembly Election';
					case 'IPL_WINNER':
						return 'IPL Winner';
					case 'HORSE_RACE':
						return 'Horse Race'
					case 'GREY_HOUND':
						return 'Grey Hound'
					default:
						return 'Sports';
				}
			}
			else if (routeName == 'SportsEventDetail') {
				return 'Detail'
			}
			else if (routeName == 'games') {
				let routeType = this.$route.params.type;
				switch (routeType) {
					case 'LIVE_CASINO':
						return 'Live Casino';
					case 'WORLD_CASINO':
						return 'World Casino';
				}
			}
			else if (routeName == 'virtual-sport') {
				return 'Virtual Sports';
			}
			else if (routeName == 'sports-book') {
				return 'Sportsbook';
			}
			else if (routeName == 'matka' || routeName == 'matka-details') {
				return 'Matka';
			}
			else if (routeName == 'notifications') {
				return 'Notifications';
			}
			else if (routeName == 'favourites') {
				return 'Favourites';
			}
			else if (routeName == 'Wallet') {
				return 'Wallet';
			}
			else if (routeName == 'BetAllDetails') {
				return 'Details';
			}
			else if (routeName == 'MyMarket') {
				return 'My Market';
			}
			else if (routeName == 'wallet-history') {
				return 'Wallet History'
			}
			else if (routeName == 'wallet-history-detail') {
				return 'Transaction Detail'
			}
			else if (routeName == 'payment-method' || routeName == 'wallet-withdraw' || routeName == 'BankDetails') {
				return 'Withdraw'
			}
			else if (routeName == 'wallet-deposit') {
				return 'Deposit'
			}
			else if (routeName == 'search') {
				return 'Search'
			}
			else if (routeName == 'bets') {
				return 'All Bets'
			}
			else if (routeName == 'AddBankAccount') {
				let routeType = this.$route.params.itemDetail;
				switch (routeType) {
					case 'bank':
						return 'Add Bank';
					default:
						return 'Add UPI';

				}
			} else if (routeName == 'CricketFight') {
				return 'CRICEKT FIGHT'
			}
			else if (routeName == 'MatchDetails' || routeName == 'PlaceBet') {
				return this.cricketFight_eventName
			}
			else if (routeName == 'MyContests') {
				return 'MY CONTESTS'
			}
			else if (routeName == 'MyContestBetDetails') {
				return this.myContest_eventName
			}
			else if (routeName == 'Crick-Notifications') {
				return 'Notifications'
			}
			else if (routeName == 'ReferEarn') {
				return 'Refer & Earn'
			}
			else if (routeName == 'racing-category' || routeName == 'racing-list-details' || routeName == 'racing-tab') {
				let routeType = this.$route.params.type;
				switch (routeType) {
					case 'horse_race':
						return 'Horse Race';
					case 'hound_race':
						return 'Grey Hound';
					default:
						return 'Racing';
				}
			}

		},
		callVoiceSearchFlag() {
			this.$emit('voice-search-flag')
		},
		showWallet() {
			if (this.$route.name == 'Wallet' || this.$route.name == 'search') {
				return false
			}
			return true
		},
		showErrorModalFunc(message) {
			this.$emit('error-modal', message);
		},
		showSuccessModalFunc(message) {
			this.$emit('success-modal', message);
		},

	},
};
</script>