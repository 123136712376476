<template>
    <div class="inner-body login-body-bg">
        <section class="register-header-wrapper">

            <div class="register-header-sec">
                <div class="container">
                    <div class="register-header-con">
                        <div class="black-arrow">
                            <a href="/"><img src="@/assets/images/back-arrow.svg" alt=""></a>
                        </div>
                        <div class="register-header-det">
                            <div class="thm-heading">
                                <h3>Login</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="register-header-banner">
                <div class="container">
                    <div class="register-banner">
                        <div class="register-banner-logo">
                            <a href="#">
                                <img src="@/assets/images/logo.png" alt="">
                            </a>
                        </div>
                        <div class="thm-heading">
                            <h2>Forgot Password</h2>
                            <p>Not to worry, we got you! let's get you a new password.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-wrapper">
                <div class="container">
                    <div class="form-sec">
                        <form>
                            <div class="form-con">

                                <div class="form-con-input ">
                                    <label for="exampleInputEmail1" class="form-label">Mobile Number</label>
                                    <div class="input-select">
                                        <input type="number" class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Enter Number" v-model="form.mobileNo">
                                        <div class="left-input-sel">
                                            <div class="left-input-img">
                                                <img class="call-in-img show" src="@/assets/images/input-call-img.svg"
                                                    alt="">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-check ps-0 invalid-otp" v-if="valError && v$.form.mobileNo.required.$invalid">
                                        <label class="form-check-label otp-warn" for="flexCheckDefault">
                                            Mobile number is required
                                        </label>
                                    </div>
                                </div>

                                <div class="form-con-input">
                                    <label for="exampleInputEmail1" class="form-label">OTP </label>
                                    <div class="input-select">
                                        <input type="number" class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Enter OTP" v-model="form.otp">

                                        <div class="left-input-sel">
                                            <div class="left-input-img">
                                                <img class="user-in-img show" src="@/assets/images/DotsSixVertical.svg"
                                                    alt="">
                                            </div>
                                        </div>

                                        <div class="right-input-sel">
                                            <div class="left-input-img w-100" v-if="!timerRunning">
                                                <button class="thm-btn border-0" type="button" @click="sendOtpCall()">Get OTP</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-check ps-0 invalid-otp" v-if="valError && v$.form.otp.required.$invalid">
                                        <label class="form-check-label otp-warn" for="flexCheckDefault">
                                            OTP is required
                                        </label>
                                    </div>
                                    <div class="form-check ps-0 invalid-otp" v-if="valError && v$.form.otp.minLength.$invalid">
                                        <label class="form-check-label otp-warn" for="flexCheckDefault">
                                            OTP length is 6
                                        </label>
                                    </div>

                                    <div class="forgot-sub-sec">
                                        <div class="form-check ps-0">
                                            <label class="form-check-label" for="flexCheckDefault" v-if="timerRunning">
                                                Time Remaining : {{ formattedTime }}
                                            </label>
                                        </div>
                                        <div class="forgot-sub-con" v-if="!timerRunning">
                                            <a href="javascript:void(0);" @click.prevent="sendOtpCall()">
                                                <h5> <span>Resend OTP</span></h5>
                                            </a>
                                        </div>
                                    </div>


                                </div>



                                <div class="form-con-input register-btn ">
                                    <div class="form-btn ">
                                        <a href="javascript:void(0);" class="thm-btn" @click.prevent="forgetPasswordCall()">Submit</a>
                                    </div>
                                    <div class="forgot-sub-con text-center">
                                        <h5>Already have an account? <router-link :to="{ name: 'Login' }">
                                                <span>Login?</span>
                                            </router-link> </h5>
                                    </div>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import api from '../services/api';
import * as apiName from '../services/urls';
import { useVuelidate } from '@vuelidate/core'
import { required, minLength } from '@vuelidate/validators'

export default {
    name: "ForgetPassword",
    setup () {
        return { v$: useVuelidate() }
    },
    data() {
        return {
            form: {
                mobileNo: "",
                otp: '',
            },
            loadingOtp: false,
            loadingUpdate: false,

            showValidationError: false,
            showErrorMobile: false,
            passwordValidation: false,
            otpValidation: false,

            showSuccessModal: false,
            successMsg: "",

            showErrorModal: false,
            errorMsg: '',
            timerRunning: false,
            seconds: 120, // 2 minutes
            timerInterval: null,
            valError: false
        };
    },
    validations () {
        return {
            form: {
                mobileNo: { required },
                otp: { required, minLength: minLength(6) }
            }
        }
    },
    computed: {
        formattedTime() {
        const minutes = Math.floor(this.seconds / 60);
        const remainingSeconds = this.seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
        }
    },
    methods: {
        startTimer() {
            this.seconds=120
            // Check if the timer is already running
            if (!this.timerRunning) {
                this.timerRunning = true;
                this.timerInterval = setInterval(() => {
                if (this.seconds > 0) {
                    this.seconds--;
                } else {
                    // Stop the timer when it reaches 0
                    this.stopTimer();
                }
                }, 1000);

                
            }
            },
            stopTimer() {
            // Clear the timer interval to stop the timer
            clearInterval(this.timerInterval);
            this.timerRunning = false;
            },
            beforeDestroy() {
            // Clear the timer interval to avoid memory leaks
            clearInterval(this.timerInterval);
        },
        refreshData() {
            this.form = {
                mobileNo: "",
                otp: '',
            },
                this.showValidationError = false,
                this.showErrorMobile = false,
                this.passwordValidation = false
        },
        sendOtpCall() {
            if (this.form.mobileNo == "") {
                this.showErrorModalFunc("Please input mobile number");

                this.showErrorMobile = true;
            }
            else {
                this.sendOtpServiceCall();
            }
        },
        sendOtpServiceCall() {
                this.loadingOtp = true;
                let data = {
                    "mobileno": this.form.mobileNo,
                };
                api.post(apiName.SEND_OTP_FORGOT_PASSWORD, data).then(response => {
                    this.loadingOtp = false;

                    if (response.status == 200) {
                        if (response.data.status_code == 200) {
                            this.startTimer();
                            this.showSuccessModalFunc(response.data.message);
                        } else {
                            this.showErrorModalFunc(response.data.data.message);
                        }
                    } else {
                        this.showErrorModalFunc(response.data.message);
                    }
                }).catch(error => {
                    this.loadingOtp = false;
                    if (error) {
                        this.showErrorModalFunc(error[0]);
                    }
                });
        },
        // forgetPasswordCall() {
        //     this.passwordValidation = false;
        //     if (this.form.mobileNo == "") {
        //         this.showErrorMobile = true;
        //     }
        //     else if (this.form.otp.toString().length != 6) {
        //         this.showValidationError = true;
        //     }
        //     else {
        //         this.forgotServiceCall();
        //     }
        // },
        forgetPasswordCall() {
            this.v$.form.$touch();
            if (!this.v$.form.$invalid) {
                this.loadingUpdate = true;
                let data = {
                    "mobileno": this.form.mobileNo,
                    "otp": this.form.otp,
                };
                api.patch(apiName.FORGOT_PASSWORD, data).then(response => {
                
                    this.loadingUpdate = false;
                    if (response) {
                        if (response.status == 200) {
                            if(response.data.data.otpverify){
                                this.showSuccessModalFunc(response.data.message);
                                localStorage.setItem('mobile_number', this.form.mobileNo)
                                this.refreshData();
                                this.$router.push('/new-password')
                            } else {
                                this.showErrorModalFunc(response.data.message);
                            }
                        
                        } else {
                            this.showErrorModalFunc(response?.message);

                        }
                    }
                }).catch(error => {
                    this.loadingUpdate = false;
                    if (error) {
                        this.showErrorModalFunc(error[0]);

                        ///this.showErrorModalFunc(error[0]);
                    }
                });
            } else {
                this.showErrorModalFunc("Please fill the required fields");
                this.valError = true;
            }
        },
        getOtp() {
            return this.form.otp.toString().replaceAll(',', '');
        },
        closeModal() {
            debugger;
            this.$refs.close_btn.click();
        },

    },
    mounted() {
        document.body.classList.add('login-body-bg', 'inner-body');
    },
    unmounted() {
        document.body.classList.remove('login-body-bg', 'inner-body');
    }
};
</script>

<style scoped>
.disable-submit {
    pointer-events: none;
}
.invalid-otp .otp-warn{
    color: red;
}
</style>