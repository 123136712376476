const state = {
  user: null,
  setGameList:null,
  homePageData:null,
  userProfile: null,
  statementBal: null
};
const getters = {
  isAuthenticated: (state) => !!state?.user,
  stateUser: (state) => state?.user,
  getUserProfile: (state) => state?.userProfile,
  getStatementBal: (state) => state?.statementBal,
  getGameList: (state) => state?.setGameList,
  getHomePageData: (state) => state?.setHomePageData,
};
const actions = {
    setUser ({ commit},user) {
        commit('setUser',user);
    },
    setUserProfile ({ commit},userProfile) {
      commit('setUserProfile',userProfile);
  },
  setStatementBal ({ commit},statementBal) {
    commit('setStatementBal',statementBal);
  },
    setGameList ({ commit},gameList) {
        commit("setGameList", gameList);
    },
    
    setHomePageData ({ commit},homePageData) {
        commit("setHomePageData", homePageData);
    },

    
};
const mutations = {
  setUser(state, user) {
    state.user = user;
  },
  setUserProfile(state, userProfile) {
    state.userProfile = userProfile;
  },
  setStatementBal(state, statementBal) {
    state.statementBal = statementBal;
  },
  setGameList(state, gameList) {
    state.setGameList = gameList;
  },
  setHomePageData(state, homePageData) {
    state.setHomePageData = homePageData;
  },

};
export default {
    state,
    getters,
    actions,
    mutations
};