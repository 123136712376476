<template>
    <section class="toster-wrapper">
        <div class="toster-fix">
            <div class="withdraw-sec">

                <div class="recent-accounts-con">
                    <div class="withdraw-con">
                        <div class="withdraw-img">
                            <img src="@/assets/images/right-icon.svg" alt="">
                        </div>
                        <div class="withdraw-img-con thm-heading">
                            <h2>Success!</h2>
                            <h3>{{ message }}</h3>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </section>
</template>
<script>
export default {
    name: "SuccessModal",
    props: ['message'],
}
</script>