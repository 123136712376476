


const EditStackModule = () => import(/* webpackChunkName: "rule-module" */ './views/Module.vue');
const EditStack = () => import(/* webpackChunkName: "rule" */ './views/EditStack.vue');


const EditStackRoutes = {
    path: '/',
    component: EditStackModule,
    children: [
        {
            path: 'edit-stack',
            name: 'edit-stack',
            component: EditStack,
        }
    ],
}

export default EditStackRoutes;
