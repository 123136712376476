import App from "./App.vue";
import router from "./router"
import store from "./store";
import VueCookies from 'vue3-cookies';
import { createApp } from 'vue'
import "./assets/css/owl.carousel.min.css";
import "./assets/css/bootstrap.min.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import "bootstrap";
import 'vue3-carousel/dist/carousel.css'
import '@fortawesome/fontawesome-free/css/all.css';
import jQuery from "jquery"
import { AUTH_TOKEN, TOKEN_EXP } from "@/shared/constants/cookies-const";
import api from '@/shared/services/api';
import * as apiName from '@/modules/sports/services/urls';

window.$ = window.jQuery = require('jquery');
window.ls = require('localstorage-slim');
import moment from "moment";
//import Interceptors from '@/shared/services/Interceptors'

// Import one of the available themes

// Import Bootstrap and BootstrapVue CSS files (order is important)
//import 'bootstrap/dist/css/bootstrap.css'
//import 'bootstrap-vue/dist/bootstrap-vue.css'

// import "./assets/js/bootstrap.bundle.min.js";
// import "./assets/js/jquery.min.js";
// //import "./assets/js/owl.carousel.min.js";



const app = createApp(App);
app.use(store);
app.use(router);
app.use(VueCookies);
app.mixin({
    data() {
        return {
            promotionBanner     : [],
            allGames            : [],
            allProvider         : [],
            mostPopularGames    : [],
            topGames            : [],
            gameShowsList       : [],
            gameCategoryList    : [],
            faqList             : [],
            promotionList       : [],
        }
    },
    created() { 
        this.inactivityTime();
        
    },
    beforeUnmount() {
        this.clearInactivityTimer();
      },
    methods: {
        inactivityTime() {
            let time;
            const resetTimer = () => {
              clearTimeout(time);
            //   time = setTimeout(() => {
            //     if (this.$cookies.get(TOKEN_EXP) && moment().unix() > Number(this.$cookies.get(TOKEN_EXP))) {
            //         this.$cookies.keys().forEach(cookie => this.$cookies.remove(cookie))
            //         this.$store.dispatch('resetAllState');
            //         this.$router.push('/');
            //         location.reload();
            //     }
            //   }, 10000);
            };
      
            
      
            window.onload = resetTimer;
            document.onmousemove = resetTimer;
      
            // Save the timer in the component's data to be able to clear it later
            this.inactivityTimer = time;
          },
          clearInactivityTimer() {
            // Clear the timer when the component is being destroyed
            clearTimeout(this.inactivityTimer);
          },
        showSuccessModalFunc(message) {
            if (message) {
                this.$emit("success-modal", message);
            }
        },
        showErrorModalFunc(message) {
            if (message) {
                this.$emit("error-modal", message);
            }
        },
        formatDate(date) {
            return moment.unix(date).format('D MMM h:mmA')
        },
        settledDate(date) {
            return moment.unix(date).format('MMM D, YYYY h:mm:ss A')
        },
        betDateFormat(date) {
            date = moment.unix(date);
            return moment(date).format('MMM D, YYYY h:mm:ss A')
        },
        generateRandomString(length=35) {
            let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            let result = '';
            const charactersLength = characters.length;
            for (let i = 0; i < length; i++) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            return result;
        }
    }
})

app.mount("#app");
//app.config.productionTip = false;

window.axios = require('axios');



// define global properties
app.config.globalProperties.fantasy_server = "fantasycricket";
app.config.globalProperties.click_server = "fantasy";