export const WALLET_HISTORY='api/wallet-history';
export const WALLET_REQUEST_HISTORY='get-request-list/depositorwithdrawal';
export const GET_ACCOUNTS_GETID='get-accounts-getid';
export const WALLET_DEPOSIT_WITHDRAW='save-user-exchange-request';
export const PAYMENT_METHODS='api/payment-methods';
export const ADD_ACCOUNT='save-admin-cashier-management';
export const BANK_DETAILS='api/bank-details';
export const ACCOUNT_DETAILS='api/account-details';
export const DELETE_ACCOUNT='delete-admin-cashier-account';
export const STACK_SETTING='stack-setting';
export const STACK_SETTING_UPDATE='update-stack-setting';

