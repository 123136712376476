
const CreditReferenceModule = () => import(/* webpackChunkName: "rule-module" */ './views/Module.vue');
const CreditReference = () => import(/* webpackChunkName: "rule" */ './views/CreditReference.vue');


const CreditReferenceRoutes = {
    path: '/',
    component: CreditReferenceModule,
    children: [
        {
            path: 'credit-reference',
            name: 'credit-reference',
            component: CreditReference,
        }
    ],
}

export default CreditReferenceRoutes;
