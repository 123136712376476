const state = {
    loading: false,
};
const getters = {
    loading: state => state?.loading,
};
const actions = {
    setLoader({ commit }, loading) {
        commit('loading', loading);
    },
};
const mutations = {
    loading(state, payload) {
        state.loading = payload
    }
};
export default {
    state,
    getters,
    actions,
    mutations
};