import { createStore } from 'vuex';
import createPersistedState from "vuex-persistedstate";
import cricketFight from '@/modules/cricket-fight/store';
import sportStore from '@/modules/sports/store';
import auth from '@/modules/authorization/store';
import matkaStore from '@/modules/matka/store';
import commonStore from '@/shared/store'

const mutations = {
  resetState(state) {
    state.auth.user = null;
  },
  
}
const actions = {
  resetAllState({commit}) {
    commit('resetState')
  }
}
const state = {
}

export default createStore({
  state,
  mutations,
  actions,
  modules: { cricketFight, sportStore, auth, matkaStore, commonStore },
  plugins: [createPersistedState()]
});