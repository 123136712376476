const AccountModule = () => import(/* webpackChunkName: "notifications-module" */ './views/Module.vue');
const ChangePassword = () => import(/* webpackChunkName: "notifications" */ './views/ChangePassword.vue');

const AccountsRoutes = {
    path: '/',
    component: AccountModule,
    children: [
        {
            path: 'change-password',
            name: 'changepassword',
            component: ChangePassword
        },
    ],
}

export default AccountsRoutes;