const BetsModule = () => import(/* webpackChunkName: "bets-module" */ './views/Module.vue');
const Bets = () => import(/* webpackChunkName: "bets" */ './views/Bets.vue');
const BetAllDetails = () => import(/* webpackChunkName: "bet-all-details" */ './views/BetAllDetails.vue');

const BetsRoutes = {
    path: '/',
    component: BetsModule,
    children: [
        {
            path: 'bets/:type/:id?',
            name: 'bets',
            component: Bets,
            meta: { requiresAuth: true },
        },
        {
            path: 'bet-details/:id?/:status?/:name?/:from?/:to?',
            name: 'BetAllDetails',
            component: BetAllDetails,
            meta: { requiresAuth: true },
        },
    ],
}

export default BetsRoutes;