<template>
    <div class="register-header-wrapper">

        <div class="register-header-sec">
            <div class="container">
                <div class="register-header-con">
                    <div class="black-arrow" @click="$router.push('/')">
                        <a href="Javascript:void(0);"><img src="@/assets/images/back-arrow.svg" alt=""></a>
                    </div>
                    <div class="register-header-det">
                        <div class="thm-heading">
                            <h3>Login</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="register-header-banner">
            <div class="container">
                <div class="register-banner">
                    <div class="register-banner-logo">
                        <a href="#">
                            <img src="@/assets/images/logo.png" alt="">
                        </a>
                    </div>
                    <div class="thm-heading">
                        <h2>Login Account</h2>
                        <p>Login with Mobile Number Or User Name choose one below</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="form-wrapper">
            <div class="container">
                <div class="form-sec">
                    <form>
                        <div class="form-con">

                            <div class="form-con-input select-input-sec">
                                <label for="loginID" class="form-label">{{ loginType == 'userId' ? 'Enter User Id' : 'Enter Number' }}</label>
                                <div class="input-select">
                                    <input type="text" class="form-control" id="loginID"
                                        aria-describedby="emailHelp" placeholder="Enter Username" v-model.trim="v$.form.userId.$model">

                                    <div class="left-input-sel">
                                        <div class="left-input-img">
                                            <img class="user-in-img show" src="@/assets/images/input-user-img.svg" alt="">
                                            <img class="call-in-img" src="@/assets/images/input-call-img.svg" alt="">
                                        </div>
                                    </div>

                                    <div class="right-input-sel right-select">
                                        <button class="selecter-btn bg-transparent border-0" @focus="selectIdTye()" tabindex="-1">
                                            <div class="right-input-img">
                                                <img class="user-in-img" :class="loginType == 'phoneNumber' ? '' : 'show'" src="@/assets/images/circle-user.svg" alt="">
                                                <img class="call-in-img" :class="loginType == 'phoneNumber' ? 'show' : ''" src="@/assets/images/smartphone.svg" alt="">
                                            </div>
                                        </button>
                                        <div class="right-select-box">
                                            <ul>
                                                <li><a href="javascript:void(0);" class="user-icon-btn" @click="selectLoginType('userId'), selectIdTye()"><img class="user-icon-sel"
                                                            src="@/assets/images/circle-user.svg" alt="">
                                                        <div class="thm-heading">
                                                            <h3 class="text-light">User Name</h3>
                                                        </div>
                                                    </a></li>
                                                <li><a href="javascript:void(0);" class="call-icon-btn" @click="selectLoginType('phoneNumber'), selectIdTye()"> <img class="call-icon-sel"
                                                            src="@/assets/images/input-call-img.svg" alt="">
                                                        <div class="thm-heading">
                                                            <h3 class="text-light">Number</h3>
                                                        </div>
                                                    </a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-check ps-0 invalid-otp"  v-if="addUserError && v$.form.userId.required.$invalid">
                                    <label class="form-check-label otp-warn text-danger" for="flexCheckDefault">
                                        User ID is required
                                    </label>
                                </div>
                            </div>

                            <div class="form-con-input">
                                <label for="loginPassword" class="form-label">Password </label>
                                <div class="input-select">
                                    <input :type="passVisible ? 'text':'password'" class="form-control" id="loginPassword"
                                        aria-describedby="emailHelp" placeholder="Enter Password" v-model.trim="v$.form.password.$model" >

                                    <div class="left-input-sel">
                                        <div class="left-input-img">
                                            <img class="user-in-img show" src="@/assets/images/lock-icon.svg" alt="">
                                        </div>
                                    </div>

                                    <div class="right-input-sel">
                                        <div class="left-input-img">
                                            <button class="border-0 bg-transparent" @click.prevent="showPassword()"><img class="user-in-img show"
                                                    src="@/assets/images/eye-icon.svg" alt=""></button>

                                        </div>
                                    </div>
                                    <!-- <img :src="captcha" alt="" srcset=""> -->
                                </div>
                                <div class="form-check ps-0 invalid-otp"  v-if="addUserError && v$.form.password.required.$invalid">
                                    <label class="form-check-label otp-warn text-danger" for="flexCheckDefault">
                                        Password is required
                                    </label>
                                </div>

                                

                                <div class="form-con-input select-input-sec">
                                <label for="captchaText" class="form-label">Captcha</label>
                                <div class="input-select">
                                    <input type="text" class="form-control" id="captchaText"
                                        aria-describedby="emailHelp" placeholder="Enter Captcha"  v-model="form.captchaText">

                                    <div class="left-input-sel">
                                        <div class="left-input-img">
                                            <img class="user-in-img show" src="@/assets/images/input-user-img.svg" alt="">
                                            <img class="call-in-img" src="@/assets/images/input-call-img.svg" alt="">
                                        </div>
                                    </div>

                                    <div class="right-input-sel-captcha right-select-d">
                                            <img :src="captcha" alt="" srcset="">
                                    </div>
                                </div>
                                <div class="form-check ps-0 invalid-otp"  v-if="addUserError && v$.form.captchaText.required.$invalid">
                                    <label class="form-check-label otp-warn text-danger" for="flexCheckDefault">
                                        Captcha is required
                                    </label>
                                </div>
                            </div> 



                                <div class="forgot-sub-sec">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" v-model="form.remember">
                                        <label class="form-check-label" for="flexCheckDefault">
                                            Remember Password
                                        </label>
                                    </div>
                                    <div class="forgot-sub-con">
                                        <router-link :to="{ name: 'ForgetPassword' }">
                                            <h5> <span>Forgot Password?</span></h5>
                                        </router-link>
                                    </div>
                                </div>

                                <div class="form-btn">
                                    <a href="" class="thm-btn" @click.prevent="signInMethodCall()" v-if="!loading">Login</a>
                                    <a href="" class="thm-btn" v-if="loading">
                                        <div class="preloader d-flex align-items-center justify-content-center">
                                            <div class="cssload-container">
                                                <div class="cssload-loading"><i></i><i></i><i></i><i></i></div>
                                            </div>
                                        </div>
                                    </a>
                                </div>

                                <div class="forgot-sub-con text-center">

                                    <h5>Don’t have an account?
                                        <router-link :to="{ name: 'SignUp' }">
                                            <span>Register?</span>
                                        </router-link>
                                    </h5>

                                </div>
                            </div>
                            

                        </div>

                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { AUTH_TOKEN, TOKEN_EXP } from '@/shared/constants/cookies-const';
import api from '../services/api';
import * as apiName from '../services/urls';
import { Modal } from 'bootstrap';
import axios from 'axios';
import {setHeaders} from '@/shared/services/headers';
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

export default {
    name: "Login",
    setup () {
        return { v$: useVuelidate() }
    },
    data() {
        return {
            form: {
                userId: "",
                password: "",
                loginType: this.loginType,
                remember: false,
                captchaText:""                
            },
            styleObject: {
                display: "none",
            },
            showValidationError: false,
            showSuccessModal: false,
            successMsg: "",
            showErrorModal: false,
            errorMsg: "",
            loading: false,
            modal: null,
            loginType: 'userId',
            passVisible: false,
            loginError: false,
            captcha: null,
            captchaId:"",
        };
    },
    validations () {
        return {
            form: {
                userId: { required },
                password: { required },
                captchaText: { required }
            }
        }
    },
    methods: {
        refreshData() {
            this.form = {
                userId: "",
                password: "",
                remember: "",
                logintype: "",

            },
                this.showValidationError = false
        },
        // signInMethodCall() {
        //     if (!this.loading) {
        //         if (this.form.userId == "" || this.form.password == "") {
        //             alert(111)
        //             this.showValidationError = true;
        //         }
        //         else {
        //             this.loginServiceCall();
        //         }
        //     }
        // },
        signInMethodCall() {
            this.v$.form.$touch();
            if (!this.v$.form.$invalid) {
                let id = this.form.userId;
                this.loading = true;
                let data = {
                    "userid"        : id.toString(),
                    "password"      : this.form.password,
                    "logintype"     : this.loginType,
                    "captchaText"   : this.form.captchaText,
                    "captchaId"     : this.captchaId
                };
                api.post(apiName.LOGIN_API, data).then(response => {
                    this.loading = false;
                    if (response.data) {
                        if (response.data.status_code == 200) {
                            if (response?.data?.status_code == 0) {
                                this.showErrorModalFunc(response?.data?.message+' Try again!');
                            }
                            else {

                                this.showSuccessModalFunc(response?.data?.message);
                                let userData = response.data.data.user;
                                console.log("userData", userData);
                                this.$cookies.set(AUTH_TOKEN, userData.token);
                                this.$cookies.set(TOKEN_EXP, userData.tokenExp);
                                window.authorization_token = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
                                axios.defaults.headers.common['Authorization'] = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`;
                                setHeaders()
                                this.setUserDataToStore(userData);
                                localStorage.setItem('mobile_number',userData)
                                //this.getGameandProvider();
                                this.refreshData();
                                // this.$router.back();
                                location.reload();
                            }
                        } else {
                            this.showErrorModalFunc(response?.data?.message );
                            this.captchMethodCall();
                        }
                    } else {
                        this.showErrorModalFunc(response?.data?.data?.error + ' Try again!');
                        this.captchMethodCall();
                    }
                }).catch(error => {
                    this.loading = false;
                    if (error) {
                        this.showErrorModalFunc(error?.data?.data?.error+' Try again!');
                    }
                }); 
            } else {
                this.showErrorModalFunc("Please fill the required fields");
                this.addUserError = true;
            }
        },
        captchMethodCall() {
                api.get(apiName.CAPTCH).then(response => {
                    this.loading = false;
                    if (response.data) {
                        if (response.data.status_code == 200) {
                            if (response?.data?.status_code == 0) {
                                this.showErrorModalFunc(response?.data?.message+' Try again!');
                            }
                            // else {

                            // }
                        } else {
                            this.showErrorModalFunc(response?.data?.message );
                        }
                        this.captcha = response.data.data.imageUri;
                        this.captchaId = response.data.data.id;
                    } else {
                        this.showErrorModalFunc(response?.data?.data?.error + ' Try again!');
                    }
                }).catch(error => {
                    this.loading = false;
                    if (error) {
                        console.log("Error Login : ", error);
                        this.showErrorModalFunc(error?.data?.data?.error+' Try again!');
                    }
                }); 
        },
        clickOnType(type) {
            this.form.type = type;
            this.styleObject.display = "none";
            this.form.userId = "";
            this.form.mobileNo = "";
        },
        getId() {
            return this.form.type == 1 ? this.form.mobileNo : this.form.userId;
        },
        closeModal() {
            this.$refs.close_btn.click();
        },
        setUserDataToStore(userData) {
            let user =
            {
                balance: userData.balance??0,
                currency: userData.currency,
                currency_slug: userData.currency_slug,
                last_login_at: userData.last_login_at,
                name: userData.name,
                role_id: userData.role_id,
                userid: userData.userid,
                exposure: userData.exposure??0,
                available_credits: userData.available_credits
            }
            this.$store.dispatch('setUser', user);
        },
        selectIdTye() {
            $('.right-select-box').toggleClass("show")
        },
        selectLoginType(type) {
            this.form.userId = ''
            this.loginType = type
        },
        showPassword() {
            this.passVisible = !this.passVisible
        }
    },
    mounted() {
        document.body.classList.add('login-body-bg','inner-body');
        this.captchMethodCall()
    },
    unmounted() {
        document.body.classList.remove('login-body-bg','inner-body');
    }

};
</script>

<style scoped>
.preloader {
    background: none;
    width: inherit;
    height: inherit;
    position: inherit;
}
.preloader .cssload-loading i {
    width: 16px;
    height: 16px;
    background: var(--secondary-color);
}

</style>