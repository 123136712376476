<template>
    <div class="modal fade" id="editeModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog open-modal-sec  thm-modal-sec">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="thm-heading">
                        <h2>Edit stake</h2>
                    </div>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
                </div>
                <div class="modal-body">
                    <div class="back-bat-btn m-0">
                        <div class="thm-gradient-but edit-input-sec">
                            <form id="updateStake" >
                                <ul>
                                    <li>
                                        <input  type="text" placeholder="Name" name="cn1" v-model="stackSetting.cn1" title="Name"> <br><br>
                                        <input  type="number" placeholder="Value" name="cv1" v-model="stackSetting.cv1" title="Value">
                                    </li>
                                    
                                    <li>
                                        <input  type="text" placeholder="Name" name="cn2" v-model="stackSetting.cn2" title="Name"> <br><br>
                                        <input  type="number" placeholder="Value" name="cv2" v-model="stackSetting.cv2" title="Value">
                                    </li>
                                    <li>
                                        <input  type="text" placeholder="Name" name="cn3" v-model="stackSetting.cn3" title="Name"> <br><br>
                                        <input  type="number" placeholder="Value" name="cv3" v-model="stackSetting.cv3" title="Value">
                                    </li>
                                    <li>
                                        <input  type="text" placeholder="Name" name="cn4" v-model="stackSetting.cn4" title="Name"> <br><br>
                                        <input  type="number" placeholder="Value" name="cv4" v-model="stackSetting.cv4" title="Value">
                                    </li>
                                    <li>
                                        <input  type="text" placeholder="Name" name="cn5" v-model="stackSetting.cn5" title="Name"> <br><br>
                                        <input  type="number" placeholder="Value" name="cv5" v-model="stackSetting.cv5" title="Value">
                                    </li>
                                    <li>
                                        <input  type="text" placeholder="Name" name="cn6" v-model="stackSetting.cn6" title="Name"> <br><br>
                                        <input  type="number" placeholder="Value" name="cv6" v-model="stackSetting.cv6" title="Value">
                                    </li>
                                </ul>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="back-thm-modal">
                    <div class="thm-modal-btn w-auto">
                        <a href="#" class="thm-btn thm-boder-btn gray-btn" data-bs-dismiss="modal">No</a>
                        <button type="button" class="thm-btn" @click="updateStake()">Yes</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="clickBet" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog open-modal-sec  thm-modal-sec">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="thm-heading">
                        <h2>On Click Bet</h2>
                    </div>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" ref="close_btn" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="back-bat-btn m-0">
                        <div class="thm-gradient-but edit-input-sec">
                            <form id="updateStake" >
                            
                                    <ul v-if="inputvalue">
                                <li><input type="number" @click="selectInputValue(0)" 
                                        v-model="inputvalue[0].value" >
                                </li>
                                <li><input type="number" @click="selectInputValue(1)"                                        v-model="inputvalue[1].value" >
                                </li>
                                <li><input type="number" @click="selectInputValue(2)" 
                                        v-model="inputvalue[2].value" >
                                </li>
                                <li><input type="number" @click="selectInputValue(3)" 
                                        v-model="inputvalue[3].value" >
                                </li>
                            </ul>
                               
                            </form>
                        </div>
                    </div>
                </div>
                <div class="back-thm-modal">
                    <div class="thm-modal-btn w-auto">
                        <a href="#" class="thm-btn thm-boder-btn gray-btn" data-bs-dismiss="modal" @click=" closeaaa()">No</a>
                        <button type="button" class="thm-btn" @click="saveClickBetValue()">Yes</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { AUTH_TOKEN } from '@/shared/constants/cookies-const';
import api from '../services/api';
import { Modal } from 'bootstrap';
import * as apiName from '../services/urls';
export default {
    name: 'EditStakeModal',
    props: ['stackSetting'],
    data() {
        return {
            cn1: '',
            cn2: '',
            cn3: '',
            cn4: '',
            cn5: '',
            cn6: '',
            cv1: '',
            cv2: '',
            cv3: '',
            cv4: '',
            cv5: '',
            cv6: '',
            inputvalue: null,
            
        }
    },
    mounted() {
        if (this.getClickBetValue()) {
            this.inputvalue = this.getClickBetValue();
        }
        else {
            this.inputvalue = [
                {
                    value: 100,
                    isSelected: true,
                },
                {
                    value: 1000,
                    isSelected: false,
                },
                {
                    value: 2000,
                    isSelected: false,
                },
                {
                    value: 5000,
                    isSelected: false,
                }
            ]
        }
    },
    methods: {
        closeaaa() {
            this.$parent.clickBetCheck = false
        },
        getClickBetValue() {
            return this.$store.getters.clickBetValue?[...this.$store.getters.clickBetValue]:null;
        },
        updateStake() {
            var stakeForm = document.getElementById("updateStake");
            var payload = new FormData(stakeForm);
            
            this.loading = true;
            window.store.dispatch("setLoader", true);
            let headers = { 'Authorization': `Bearer ${this.$cookies.get(AUTH_TOKEN)}` }
            api.put(apiName.STACK_SETTING_UPDATE, payload, { headers: headers }).then(response => {
                window.store.dispatch("setLoader", false);
                this.loading = false;
                if (response.status == 200) {
                   this.$parent.showSuccessModalFunc('Stake updated successfully');
                }
            }).catch(error => {
                window.store.dispatch("setLoader", false);
                this.loading = false;
                if (error) {
                    this.showErrorModalFunc(error[0]);
                }
            });
        },
        selectInputValue(index) {
            for (let i = 0; i < this.inputvalue.length; i++) {
                this.inputvalue[i].isSelected = false;
            }
            this.inputvalue[index].isSelected = true;
        },
        saveClickBetValue() {
           console.log("Input Value", this.inputvalue);
           const selectedValues = this.inputvalue.filter(item => item.isSelected).map(item => item.value);
           localStorage.setItem('setClickBet', selectedValues)
           this.$parent.clickBetCheck = true;
           this.$parent.getClickBetSelectedValue();
           this.$refs.close_btn.click();
           
           
        }
    }
}
</script>

<style></style>