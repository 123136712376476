const ReferEarnModule = () => import(/* webpackChunkName: "refer-earn-module" */ './views/Module.vue');
const ReferEarn = () => import(/* webpackChunkName: "refer-earn" */ './views/ReferEarn.vue');

const ReferEarnRoutes = {
    path: '/',
    component: ReferEarnModule,
    children: [
        {
            path: 'refer-earn',
            name: 'ReferEarn',
            component: ReferEarn,
        }
    ],
}

export default ReferEarnRoutes;